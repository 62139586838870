
import Vue from 'vue';
import InlineSvg from 'vue-inline-svg';
import BaseButton from '@/components/BaseButton';
import {computed, ref, useRoute} from '@nuxtjs/composition-api';
import {onSSR} from '@vue-storefront/core';
import {useJobs} from '@/integrations/softgarden/src/composables/useJobs';

export default Vue.extend({
  name: 'JobPreviewComponent',
  components: {
    InlineSvg,
    BaseButton,
  },
  props: {
    classes: {
      type: String,
    },
    maxJobsToDisplay: {
      type: String,
    },
    backgroundColor: {
      type: String,
      default: 'light',
    },
    styles: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'text-link'].includes(value),
    },
  },
  setup() {
    const {
      jobs,
      search: searchJobs,
    } = useJobs('JOB_PREVEIW');

    const jobCategorySearchFilters = [];
    const workExperiencesSearchFilters = [];
    const paramQuery = useRoute().value.query;

    onSSR(async () => {
      await searchJobs();
    });

    return {
      jobs,
      jobCategorySearchFilters,
      workExperiencesSearchFilters,
      searchJobs,
      category: paramQuery?.category ?? null,
      location: paramQuery?.location ?? null,
      careerLevel: paramQuery?.careerLevel ?? null,
    };
  },
  expose: ['citySearchFilters'],
  data(){
    return {
      maxJobsToShow: this.maxJobsToDisplay,
      filter: {
        category: this.category,
        location: this.location,
        careerLevel: this.careerLevel,
      },
      citySearchFilters: [],
      categoryFilter: this.category,
      locationFilter: this.location,
      careerLevelFilter: this.careerLevel,
      filteredJobs: this.jobs || [],
    };
  },
  mounted(){
    if(Array.isArray(this.jobs)) {
      this.jobs.forEach(job => {
        if(job.hasOwnProperty('geo_city') && !this.citySearchFilters.includes((job.geo_city))) {
          this.citySearchFilters.push(job.geo_city);
        }

        this.jobCategorySearchFilters = this.jobCategorySearchFilters.concat(job.jobCategories.filter(jobCategory => !this.jobCategorySearchFilters.includes(jobCategory)));
        this.workExperiencesSearchFilters = this.workExperiencesSearchFilters.concat(job.audiences.filter(workExperience => !this.workExperiencesSearchFilters.includes(workExperience)));
      });
    }

    this.categoryFilter = this.$route.query.category || null;
    this.locationFilter = this.$route.query.location || null;
    this.careerLevelFilter = this.$route.query.careerLevel || null;

    this.filterJobs();
  },
  methods: {
    convertDateByTimestamp: (timestamp) => {
      let date = new Date(timestamp);
      let year = date.toLocaleString('default', { year: 'numeric' });
      let month = date.toLocaleString('default', { month: '2-digit' });
      let day = date.toLocaleString('default', { day: '2-digit' });
      return `${day}.${month}.${year}`;
    },
    showAllJobs(e) {
      this.maxJobsToShow = '0';
      this.filteredJobs = [];

      this.setLocationFilter(undefined);
      this.setCategoryFilter(undefined);
      this.setCareerLevelFilter(undefined);
      this.applyFilters();
    },
    setLocationFilter(data) {
      if (this.locationFilter === data) { return; }
      this.locationFilter = data;
      this.changeUrlParam();
    },
    setCategoryFilter(data) {
      if (this.categoryFilter === data) { return; }
      this.categoryFilter = data;
      this.changeUrlParam();
    },
    setCareerLevelFilter(data) {
      if (this.careerLevelFilter === data) { return; }
      this.careerLevelFilter = data;
      this.changeUrlParam();
    },
    changeUrlParam() {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          location: this.locationFilter || null,
          category: this.categoryFilter || null,
          careerLevel: this.careerLevelFilter || null,
        },
      });
    },
    async submitForm(e) {
      e.preventDefault();
      this.applyFilters();
      this.filterJobs();
    },
    applyFilters() {
      this.filter = {
        location: this.locationFilter,
        category: this.categoryFilter,
        careerLevel: this.careerLevelFilter,
      };
    },
    filterJobs() {
      this.maxJobsToShow = this.maxJobsToDisplay;

      this.filteredJobs = this.jobs;
      this.filteredJobs = this.locationFilter ? this.filteredJobs.filter(job => job?.geo_city === this.locationFilter) : this.filteredJobs;
      this.filteredJobs = this.categoryFilter ? this.filteredJobs.filter(job => job?.jobCategories.filter(jobCategory => jobCategory === this.categoryFilter).length > 0) : this.filteredJobs;
      this.filteredJobs = this.careerLevelFilter ? this.filteredJobs.filter(job => job?.audiences.filter(workExperience => workExperience === this.careerLevelFilter).length > 0) : this.filteredJobs;
    },
    filterIsSet() {
      return !!(this.filter.location || this.filter.category || this.filter.careerLevel);
    },
    filterMaxShownJobs() {
      if (!this.filterIsSet()) {
        return this.jobs.filter((job,index) => {
          if (index < (parseInt(this.maxJobsToShow)) || parseInt(this.maxJobsToShow) <= 0) {
            return job;
          }
        });
      } else {
        return this.filteredJobs;
      }
    },
  },
});
