
import InlineSvg from 'vue-inline-svg';

export default {
  name: 'DropdownMenu',
  components: {
    InlineSvg,
  },
  props: {
    classes: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    selected: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    selectItem: function (e) {
      let selectedOption = e.target.dataset.value || null;
      this.$emit('selected', selectedOption);
      this.isOpen = false;
    },
    toggleDropdown() {
      if(this.$refs.input.classList.contains('has-error')) {
        return;
      }
      this.isOpen = !this.isOpen;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false;
      }
    },
    getItemValue(item) {
      return typeof item === 'object' ? item.value : item;
    },
    getItemLabel(item) {
      return typeof item === 'object' ? item.label : item;
    },
    getSelectedItemLabel() {
      let selectedItem = this.items.filter(item => this.getItemValue(item) === this.selected)[0];
      return this.getItemLabel(selectedItem);
    },
    onBlur: function(e) {
      this.$refs.input.classList.remove('has-error');
      this.$emit('blur', e);
    },
  },
};
